import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const initiateState: () => {
  language: string;
} = () => ({
  language: "en",
});

export default new Vuex.Store({
  state: initiateState,
  getters: {
    language(state) {
      return state.language;
    },
  },
  mutations: {
    SET_LANGUAGE(state, value) {
      state.language = value;
    },
  },
  actions: {},
  modules: {},
});
