
import { Vue, Component, Watch } from "vue-property-decorator";
import data from "../../../public/locales/data.json";

@Component({})
export default class Navigation extends Vue {
  /**
   * Props
   **/
  /**
   * Data
   **/
  selected = this.localLanguage;
  languages = [
    {
      lang: "english",
      value: "en",
    },
    {
      lang: "french",
      value: "fr",
    },
    {
      lang: "spanish",
      value: "es",
    },
  ];
  content = {};

  get localLanguage(): string {
    console.log("working");
    let locale = window.navigator.languages
      ? window.navigator.languages[0]
      : window.navigator.language;

    if (locale === "en-US") {
      locale = "en";
    }
    this.$store.commit("SET_LANGUAGE", locale);
    return locale;
  }

  @Watch("$store.state.language") getLanguage() {
    this.getCopy();
  }

  scrollRollsIntoView(): void {
    const roles = document.getElementById("roles");
    if (roles != null) {
      roles.scrollIntoView({ behavior: "smooth" });
    }
  }

  scrollContactIntoView(): void {
    const roles = document.getElementById("contact");
    if (roles != null) {
      roles.scrollIntoView({ behavior: "smooth" });
    }
  }

  selectLanguage(): void {
    this.$store.commit("SET_LANGUAGE", this.selected);
  }

  getCopy(): void {
    this.content = data[this.$store.state.language as keyof typeof data];
  }

  created(): void {
    this.getCopy();
  }
  // lifecycle methods
}
