
import { Vue, Component } from "vue-property-decorator";
import Navigation from "@/components/nav/nav-presenter.vue";

@Component({
  components: { Navigation },
  metaInfo: {
    title: "Alexis Hindle - Portfolio",
    meta: [
      {
        name: "description",
        vmid: "description",
        content:
          "Frontend Developer that loves all things code, ocean and endurance sports",
      },
      {
        name: "keywords",
        content:
          "frontend, developer, frontend developer, software, software engineer, ocean, endurance, endurance sports, diving, sailing, vue, angular, javascript, html, typescript",
      },
    ],
  },
})
export default class App extends Vue {}
